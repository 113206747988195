<template>
  <Nav no-top />
  <div class="max-w-450 mx-auto"><RouterView /></div>
  <div class="w-full text-center my-6">
    <div class="quicksand">
      Copyright &copy; 2022 - 2023 THBWiki, VoileLabs. All rights reserved. Licensed under GPL-3.0.&ensp;
    </div>
    <div>
      <a target="_blank" rel="noopener noreferrer" href="https://jq.qq.com/?k=0BnkgDKx">反馈问题</a>&ensp;
      <a target="_blank" rel="noopener noreferrer" href="/nav">往届结果</a>
    </div>
  </div>
</template>
